import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Page404 from './pages/Page404';
import { appRoutes, otherRoutes } from './routes';
import Layout from './components/Layout/Layout';
import ProtectedRoute from './routes/ProtectedRoute';

function App() {
  const router = createBrowserRouter([
    {
      children: appRoutes,
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),
      errorElement: <Page404 />,
    },
    {
      children: otherRoutes,
      element: <Layout />,
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
