import React from 'react';
import navigationItems from './navigationItems';

const EventType = React.lazy(() => import('../pages/EventType'));
const EventDate = React.lazy(() => import('../pages/EventDate'));
const BasicData = React.lazy(() => import('../pages/BasicData'));
const AdditionalData = React.lazy(() => import('../pages/AdditionalData'));
const Consents = React.lazy(() => import('../pages/Consents'));
const ThankYou = React.lazy(() => import('../pages/ThankYou'));

const appRoutes = [
  { element: <EventType />, path: navigationItems.HOME.path },
  { element: <EventDate />, path: navigationItems.EVENT_DATE.path },
  { element: <BasicData />, path: navigationItems.BASIC_DATA.path },
  { element: <AdditionalData />, path: navigationItems.ADDITIONAL_DATA.path },
  { element: <Consents />, path: navigationItems.CONSENTS.path },
];

const otherRoutes = [{ element: <ThankYou />, path: navigationItems.THANK_YOU.path }];

export { appRoutes, otherRoutes };
