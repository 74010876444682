const navigationItems = {
  ADDITIONAL_DATA: {
    getPath: (id?: string) => (id ? `/word/${id}/additional-data` : '/word/:id/additional-data'),
    path: '/word/:id/additional-data',
    title: 'Wprowadź dane w celu rezerwacji',
  },
  BASIC_DATA: {
    getPath: (id?: string) => (id ? `/word/${id}/basic-data` : '/word/:id/basic-data'),
    path: '/word/:id/basic-data',
    title: 'Wprowadź dane w celu rezerwacji',
  },
  CONSENTS: {
    getPath: (id?: string) => (id ? `/word/${id}/consents` : '/word/:id/consents'),
    path: '/word/:id/consents',
    title: 'Wprowadź dane w celu rezerwacji',
  },
  EVENT_DATE: {
    getPath: (id?: string) => (id ? `/word/${id}/event-date` : '/word/:id/event-date'),
    path: '/word/:id/event-date',
    title: 'Wybierz termin kursu',
  },
  HOME: { path: '/word/:id', title: 'Wybierz rodzaj kursu' },
  THANK_YOU: { path: '/payment/:id', title: 'Podsumowanie Płatności' },
};

export const stepsItems = [
  { id: 1, item: navigationItems.HOME },
  { id: 2, item: navigationItems.EVENT_DATE },
  { id: 3, item: navigationItems.BASIC_DATA },
  { id: 4, item: navigationItems.ADDITIONAL_DATA },
  { id: 5, item: navigationItems.CONSENTS },
  { id: 6, item: navigationItems.THANK_YOU },
];

export default navigationItems;
