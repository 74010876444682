import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Consents, Participant } from '@/api/participant';
import { OrganizationEvent } from '@/api/event';
import { EventTypeTags, ValueOf } from '@/api/types';

export type EventType = {
  firstCourseDate: string | null;
  id: number | null;
  name: string;
  tag: ValueOf<EventTypeTags> | null;
};

interface BookingState<T = undefined, R = undefined, S = undefined> {
  event?: S;
  eventType?: T;
  participant?: R;
}

const initialEventState = {
  date: undefined,
  description: undefined,
  duration: undefined,
  endsAt: undefined,
  eventTypeId: undefined,
  eventTypeName: undefined,
  excludedCategories: undefined,
  id: undefined,
  location: undefined,
  name: undefined,
  price: undefined,
  startsAt: undefined,
};

const initialState: BookingState<EventType, Partial<Participant>, Partial<OrganizationEvent>> = {
  event: initialEventState,
  eventType: {
    firstCourseDate: null,
    id: null,
    name: '',
    tag: null,
  },
  participant: {
    birthDate: '',
    birthPlace: '',
    companyName: '',
    consents: {
      acceptRodo: false,
      acceptSelfDeclaration: undefined,
      acceptThatTrainingWontWorkWhenPointsThresholdReached: undefined,
      acceptTrainingRules: false,
      hasNotParticipatedToReductionTraining: undefined,
      hasValidDriverLicense: undefined,
      readInformationClause: undefined,
      readSubstanceAbuseInformationClause: undefined,
    },
    email: '',
    firstName: '',
    idCardNumber: '',
    lastName: '',
    nip: '',
    pesel: '',
    phone: '',
    postalCode: '',
    streetName: '',
    streetNumber: '',
    type: null,
    voivodeship: undefined,
  },
};

const slice = createSlice({
  initialState,
  name: 'booking',
  reducers: {
    clearEvent: (state: BookingState<EventType, Partial<Participant>, Partial<OrganizationEvent>>) => ({
      ...state,
      event: initialEventState,
    }),
    setConsents: (
      state: BookingState<EventType, Partial<Participant>, Partial<OrganizationEvent>>,
      action: PayloadAction<Consents>,
    ) => ({
      ...state,
      participant: {
        ...state.participant,
        consents: {
          ...state.participant?.consents,
          ...action.payload,
        },
      },
    }),
    setEvent: (
      state: BookingState<EventType, Partial<Participant>, Partial<OrganizationEvent>>,
      action: PayloadAction<OrganizationEvent>,
    ) => ({
      ...state,
      event: {
        ...action.payload,
      },
    }),
    setEventType: (
      state: BookingState<EventType, Partial<Participant>, Partial<OrganizationEvent>>,
      action: PayloadAction<EventType>,
    ) => ({
      ...state,
      eventType: {
        firstCourseDate: action.payload.firstCourseDate,
        id: action.payload.id,
        name: action.payload.name,
        tag: action.payload.tag,
      },
    }),
    setParticipant: (
      state: BookingState<EventType, Partial<Participant>, Partial<OrganizationEvent>>,
      action: PayloadAction<Partial<Participant>>,
    ) => ({
      ...state,
      participant: {
        ...state.participant,
        ...action.payload,
      },
    }),
  },
});

export const selectEventType = (state: RootState) => state.booking.eventType;
export const selectEvent = (state: RootState) => state.booking.event;
export const selectParticipant = (state: RootState) => state.booking.participant;

const { name, reducer, actions } = slice;

export { name, actions, reducer };
