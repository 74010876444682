import { api } from './index';
import { mapResponseOrganization } from './mappers/organization';

export interface Organization {
  email: string;
  externalId: string;
  id: number;
  name: string;
  phone: string;
  selfDeclaration: OrganizationSelfDeclaration | null;
  type: string;
}

export interface OrganizationResponse {
  email: string;
  external_id: string;
  id: number;
  name: string;
  phone: string;
  self_declaration: OrganizationSelfDeclarationData | null;
  type: string;
}

export interface OrganizationSelfDeclaration {
  default?: string | null;
  substanceAbuse?: string | null;
}

export interface OrganizationSelfDeclarationData {
  default?: string | null;
  substance_abuse?: string | null;
}

const organizationAPi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrganization: builder.query<Organization, { id: string | undefined }>({
      providesTags: ['Organization'],
      query: ({ id }) => ({
        url: `/organization/${id}`,
      }),
      transformResponse: (response: { data: OrganizationResponse }) => {
        return mapResponseOrganization(response.data);
      },
    }),
  }),
});

export const { useGetOrganizationQuery } = organizationAPi;
