import { Box } from '@mui/material';
import { stepsItems } from '@/routes/navigationItems';
import Step from './Step';

interface StepperProps {
  activeId: number;
}

const Stepper = ({ activeId }: StepperProps) => {
  return (
    <Box display='flex' sx={{ mb: 2 }}>
      {stepsItems.map((item) => (
        <Step key={item.id} active={item.id === activeId || item.id < activeId} />
      ))}
    </Box>
  );
};
export default Stepper;
