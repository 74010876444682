import { Snackbar, Alert, SnackbarCloseReason } from '@mui/material';
import React from 'react';
import { useAppSelector } from '@/hooks/hooks';
import { selectNotifications } from '@/store/notification/reducer';
import { useNotification } from '@/hooks/useNotification';

const Notification = () => {
  const notifications = useAppSelector(selectNotifications);
  const { clearNotification } = useNotification();
  const handleClose = (id: string) => (_: unknown, reason?: SnackbarCloseReason) =>
    reason !== 'clickaway' && clearNotification(id);
  return (
    <>
      {notifications.map((notification) => (
        <Snackbar
          key={notification.id}
          open={notification.open}
          autoHideDuration={notification.timeout}
          onClose={handleClose(notification.id as string)}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        >
          <Alert
            variant='filled'
            onClose={handleClose(notification.id as string)}
            sx={{ whiteSpace: 'pre-line' }}
            severity={notification.type}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};
export default Notification;
