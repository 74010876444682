import {
  Organization,
  OrganizationResponse,
  OrganizationSelfDeclaration,
  OrganizationSelfDeclarationData,
} from '../organization';

export const mapResponseOrganization = (organization: OrganizationResponse): Organization => {
  return {
    email: organization.email,
    externalId: organization.external_id,
    id: organization.id,
    name: organization.name,
    phone: organization.phone,
    selfDeclaration: mapResponseSelfDeclaration(organization.self_declaration),
    type: organization.type,
  };
};

const mapResponseSelfDeclaration = (
  selfDeclaration?: OrganizationSelfDeclarationData | null,
): Partial<OrganizationSelfDeclaration> | null => {
  if (!selfDeclaration) {
    return null;
  }

  return {
    default: selfDeclaration?.default,
    substanceAbuse: selfDeclaration?.substance_abuse,
  };
};
