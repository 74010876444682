import { Outlet, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useGetOrganizationQuery } from '@/api/organization';
import Loader from '@/components/Layout/Loader';

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { id } = useParams<{ id?: string }>();
  const { data, isLoading, refetch } = useGetOrganizationQuery({
    id,
  });
  const navigate = useNavigate();
  const hasAccess = data && data.externalId === id;

  useEffect(() => {
    refetch()
      .unwrap()
      .catch((error) => {
        console.log(error);
        navigate('/', { replace: true });
      });
  }, [refetch]);

  useEffect(() => {
    if (!hasAccess && !isLoading) {
      navigate('/', { replace: true });
    }
  }, [hasAccess, isLoading]);

  // TODO: need to be refactored to use suspense when RTK library will be updated to support React Suspense, then it can be removed
  if (isLoading) return <Loader />;

  return children ? children : <Outlet />;
};
export default ProtectedRoute;
