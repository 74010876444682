import Stepper from './Stepper';
import { Box, Typography } from '@mui/material';
import { matchRoutes, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import navigationItems, { stepsItems } from '@/routes/navigationItems';

const Header = () => {
  const location = useLocation();

  const routeMath = useMemo(() => {
    return matchRoutes(Object.values(navigationItems), location);
  }, [location]);

  const title = useMemo(() => {
    return routeMath?.[0].route.title || '';
  }, [routeMath]);

  const activeId = useMemo(() => {
    const currentStep = stepsItems.find((step) => step.item.path === routeMath?.[0].route.path);
    return currentStep?.id || 0;
  }, [routeMath]);

  return (
    <Box display='flex' flexDirection='column'>
      <Stepper activeId={activeId} />
      <Typography variant='h3' sx={{ fontWeight: 700, mt: 3 }}>
        {title}
      </Typography>
    </Box>
  );
};
export default Header;
