import { styled } from '@mui/material/styles';

const Step = styled('div')<{ active: boolean }>(
  ({ theme, active }) => `
  background-color: ${active ? theme.palette.primary.main : theme.palette.grey.A400};
  height: 7px;
  width: 100%;
  margin: ${theme.spacing(0.5)};
  border-radius: 7px;
`,
);

export default Step;
