import { combineReducers } from '@reduxjs/toolkit';

import { reducer as bookingReducer } from './booking/reducer';
import { reducer as notificationReducer } from './notification/reducer';
import { api } from '@/api';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  booking: bookingReducer,
  notifications: notificationReducer,
});
export default rootReducer;
