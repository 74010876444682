import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, CssBaseline, Typography } from '@mui/material';
import Loader from './Loader';
import Header from '../Header';

const Layout = () => {
  return (
    <>
      <CssBaseline />
      <Box
        component='main'
        sx={{
          backgroundColor: (theme) => theme.palette.grey[100],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          p: 12,
        }}
      >
        <Header />
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
        <Typography variant='body2' sx={{ mt: 2 }}>
          Stworzone przez <a href='https://prawko.pl/'>Prawko.pl</a>
        </Typography>
      </Box>
    </>
  );
};
export default Layout;
